<!-- 交易查询 列表页面 -->
<template>
    <div class="mt-4 mx-4">
        <ath-breadcrumbs :items="navigationItems" flat="false" txtColor="" bgColor=""/>
        <v-row>
            <v-col>
                <ath-date-picker :label="this.$t('transRec.startDate')"  v-model="startDate"  :minDate="minDate" :maxDate="maxDate"/>
            </v-col>
            <v-col>
                <ath-date-picker :label="this.$t('transRec.endDate')"  v-model="endDate" :minDate="minDate" :maxDate="maxDate"/>
            </v-col>
            <v-col>
                <v-autocomplete  v-model="channelId" :label="this.$t('transRec.channelId')"
                    :items="channelIdItems"
                    :item-text="'label'"
                    :item-value="'value'"/>
            </v-col>
            <v-col>
                <v-autocomplete  v-model="transType" :label="this.$t('transRec.transType')"
                    :items="transTypeItems"
                    :item-text="'label'"
                    :item-value="'value'"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-autocomplete  v-model="merId" :label="this.$t('transRec.merName')"
                    :items="merchantsItems"
                    :item-text="'label'"
                    :item-value="'value'"
                    @change="changeMerchants"/>
            </v-col>
            <v-col>
                <v-autocomplete  v-model="termId" :label="this.$t('transRec.termId')"
                    :items="termIdItems"
                    :item-text="'label'"
                    :item-value="'value'"/>
            </v-col>
            <v-col>
                <v-autocomplete  v-model="transState" :label="this.$t('transRec.transState')"
                    :items="transStateItems"
                    :item-text="'label'"
                    :item-value="'value'"/>
            </v-col>
            <v-col>
                <ath-input-text
                    :label="this.$t('transRec.merTransId')"
                    v-model="merTransId"
                    maxlength="32"
                    />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <ath-button  class="primary"  :text="this.$t('action.exportCSV')" @click="exportCSVBtn" icon="mdi-arrow-down-thick"/>
                <ath-button  class="primary"  style="margin-left: 30px"  icon="mdi-reload" :text="this.$t('common.operate.reset')" @click="clickReset" />
                <ath-button  class="primary"  style="margin-left: 30px"  :text="this.$t('label.search')" @click="searchBtn" icon="mdi-magnify"/>
            </v-col>
            <v-col style="text-align: right;"><p style="color:red; ">{{tips}}</p></v-col>
        </v-row>
        <ath-data-table style="margin-top: 12px"
            :headers="getHeaders"
            :items="items"
            @action:row="onActionRow"
            :total="total"
            @update:options="onUpdateOptions"
            :options.sync="dTableOptions"
        >
            <template  v-slot:item.attach="{item}">
                <span v-if="item.attach != '' && item.attach != null"  @click="downloadAttach(item.attach)" style="cursor:pointer;color:#1976d2;" >{{view}}</span>
            </template>
        </ath-data-table>
    </div>

</template>

<script>
export default {
    name: 'TransRec',
    props: {},
    data() {
        return {
            navigationItems: [
                {text:this.$t('menu.transRec'),disabled:true,to:''}
            ],
            headers: [],
            items: [],
            total: 0,
            options: '',
            index:0,
            dTableOptions: {
                page: 1,
                itemsPerPage: 10,
                sortBy: [],
                sortDesc: [],
                multiSort: false,
                mustSort: false,
            },
            channelIdItems: [],
            channelIdMap:{},
            transTypeItems: [],
            transTypeMap:{},
            merchantsItems: [],
            merchantsMap:{},
            termIdItems: [],
            transStateItems: [],
            transStateMap:{},
            transCurrMap: {},
            minDate: this.setBeforeDate(),
            maxDate: this.setStartDate(),
            startDate: this.setStartDate(),
            endDate: this.setStartDate(),
            channelId: "",
            transType: "",
            merId: "",
            termId: "",
            transState: "",
            merTransId: "",
            view: this.$t('common.operate.view'),
            merIds:[],
            channelIds: [],
            transStates: [],
            transTypes: [],
            tips:this.$t('transRec.msg.tips'),
            defaultChannel:"",
        };
    },
    computed: {
        getHeaders(){
            let headers = [
                { text: this.$t('transRec.merId'), value: 'merId', sortable:false},
                { text: this.$t('transRec.merName'), value: 'merName', sortable:false},
                { text: this.$t('transRec.transState'), value: 'transStateName', sortable:false},
                { text: this.$t('transRec.createTime'), value: 'createTime', sortable:false},
                { text: this.$t('transRec.transCurType'), value: 'transCurTypeName', sortable:false},
                { text: this.$t('transRec.transAmt'), value: 'transAmt', sortable:false},
                { text: this.$t('transRec.channelId'), value: 'channelIdName', sortable:false},
                { text: this.$t('transRec.transType'), value: 'transTypeName', sortable:false},
                { text: this.$t('transRec.merTransId'), value: 'merTransId', sortable:false},
                { text: this.$t('transRec.cccNo'), value: 'cccNo', sortable:false},
                { text: this.$t('transRec.authNo'), value: 'authNo', sortable:false},
                { text: this.$t('transRec.billNo'), value: 'billNo', sortable:false},
                { text: this.$t('transRec.attach'), value: 'attach', sortable:false},
                { text: this.$t('transRec.termId'), value: 'termId', sortable:false}
            ];
            return headers;
        }
    },
    created() {
        this.initData();
    },
    watch: {
        '$athlon.i18n.locale': {
            handler(newValue) {
                this.navigationItems = [
                    {text:this.$t('menu.transRec'),disabled:true,to:''}
                ];
                this.tips = this.$t('transRec.msg.tips');
                this.initData();
            },
            deep: true
        }
    },
    methods: {
        onUpdateOptions(options) { //后端分页，监听翻页事件
            this.delRerocds = [];
            this.options = options;
            if(this.items.length > 0){
                this.fetchData();
            }
        },
        initData(){
            this.$loading(this.$t('msg.loading'));
            this.merchantsItems = [];
            this.channelIdItems = [];
            this.transTypeItems = [];
            this.transStateItems = [];
            let langcode = this.$athlon.i18n.locale;
            langcode = langcode.replace(/-/g, '_');
            let params = {
                langCode: langcode,
            }
            this.$athlon.request
            .post('transRec/getDataDict', params, this.$athlon.config.api.requestParamheaders)
            .then((res) => {
                if(res.data != null){
                    if(res.data.merchantsList != null){
                        this.merchantsMap = res.data.merchantsList;
                        for(let key in res.data.merchantsList ){
                            this.merchantsItems.push({ label: this.$t("athlon.common.all"), value:''},{ label: res.data.merchantsList[key], value:key});
                            this.merIds.push(key);
                        }
                    }
                    if(res.data.channelTypeList != null){
                        this.channelIdMap = res.data.channelTypeList;
                        for(let key in res.data.channelTypeList ){
                            if(this.defaultChannel == "") this.defaultChannel = key;
                            if(this.channelId == "") this.channelId = key;
                            this.channelIdItems.push({ label: res.data.channelTypeList[key], value:key});
                            this.channelIds.push(key);
                        }
                    }
                    if(res.data.transTypeList != null){
                        this.transTypeMap = res.data.transTypeList;
                        for(let key in res.data.transTypeList ){
                            this.transTypeItems.push({ label: this.$t("athlon.common.all"), value:''},{ label: res.data.transTypeList[key], value:key});
                            this.transTypes.push(key);
                        }
                    }
                    if(res.data.transStateList != null){
                        this.transStateMap = res.data.transStateList;
                        for(let key in res.data.transStateList ){
                            this.transStateItems.push({ label: this.$t("athlon.common.all"), value:''},{ label: res.data.transStateList[key], value:key});
                            this.transStates.push(key);
                        }
                    }
                    if(res.data.transCurrList != null){
                        this.transCurrMap = res.data.transCurrList;
                    }
                }
                // this.fetchData();
                // this.index = 1;
                this.$loading(false);
            })
            .catch((err) => {
                this.$loading(false);
                this.$snackbar(this.$t('error.500')+`：${err.message}`);
            });
        },
        changeMerchants(val){
            this.termIdItems = [];
            if(val == "")return;
            let params = {
                merId: val
            }
            this.$athlon.request
            .post('transRec/getTermList', params, this.$athlon.config.api.requestParamheaders)
            .then((res) => {
                if(res.data != null){
                    if(res.data.termList != null){
                        for(let key in res.data.termList ){
                            this.termIdItems.push({ label: this.$t("athlon.common.all"), value:''},{ label: res.data.termList[key], value:key});
                        }
                    }
                }
            })
            .catch((err) => {
                this.$loading(false);
                this.$snackbar(this.$t('error.500')+`：${err.message}`);
            });
        },
        fetchData(){
            if(this.startDate>this.endDate){
               this.tipsMsg(this.$t('transRec.msg.startDateMustlessEndDate'));
               return;
            }
            let channelIdParam = [];
            if(this.channelId != ""){
                channelIdParam.push(this.channelId);
            }else{
                channelIdParam = this.channelIds;
            }
            let merIdParam = [];
            if(this.merId != ""){
                merIdParam.push(this.merId);
            }else{
                merIdParam = this.merIds;
            }
            let transTypeParam = [];
            if(this.transType != ""){
                transTypeParam.push(this.transType);
            }else{
                transTypeParam = this.transTypes;
            }
            let transStateParam = [];
            if(this.transState != ""){
                transStateParam.push(this.transState);
            }else{
                transStateParam = this.transStates;
            }

            this.$loading(this.$t('msg.loading'));
            let limit = this.options.itemsPerPage || 10;
            let offset = (this.options.page - 1) * this.options.itemsPerPage || 0;
            if(limit == -1){
                limit = 1000;
            }
            let langcode = this.$athlon.i18n.locale;
            langcode = langcode.replace(/-/g, '_');
            const params = {
                "startDate" : this.startDate,
                "endDate": this.endDate,
                "channelIds": channelIdParam,
                "transTypes": transTypeParam,
                "transStates" : transStateParam,
                "merIds": merIdParam,
                "termId": this.termId,
                "merTransId":this.merTransId,
                "langCode": langcode,
                "startRowId": offset,
                "endRowId": limit,
                };
            this.$athlon.request
                .post('transRec/getTransRecList',params, this.$athlon.config.api.requestParamheaders)
                .then((res) => {
                    this.total = res.data.totalCount;
                    for(let i=0; i<res.data.dataList.length; i++){
                        res.data.dataList[i].transStateName = this.transStateMap[res.data.dataList[i].transState];
                        res.data.dataList[i].transCurTypeName = this.transCurrMap[res.data.dataList[i].transCurType]
                        res.data.dataList[i].channelIdName = this.channelIdMap[res.data.dataList[i].channelId]
                        res.data.dataList[i].transTypeName = this.transTypeMap[res.data.dataList[i].transType]
                    }
                    this.items = res.data.dataList;
                    this.$loading(false);
                })
                .catch((err) => {
                    this.$loading(false);
                    this.$snackbar(this.$t('error.500')+`：${err.message}`);
                });

        },
        searchBtn(){
            this.dTableOptions.page=1;
            this.dTableOptions.itemsPerPage=this.options.itemsPerPage;
            this.fetchData();
        },
        clickReset(){
            this.startDate = this.setStartDate();
            this.endDate = this.setStartDate();
            this.channelId = this.defaultChannel;
            this.transType = "";
            this.merId = "";
            this.termId = "";
            this.transState = "";
            this.merTransId = "";
            this.items = [];
            this.total =  0;
            // this.fetchData();
        },
        setStartDate() {
            let date = new Date();
            let year = date.getFullYear() + '';
            let month = date.getMonth() + 1 > 9 ? (date.getMonth() + 1) + '' : '0' + (date.getMonth() + 1);
            let day = date.getDate() > 9 ? date.getDate() + '' : '0' + date.getDate();
            return year + '-' + month + '-' + day ;
        },
        setBeforeDate() {
            let currDate = new Date();
            let dateDay = currDate.getDate();
            let date = new Date(currDate.setDate(dateDay - 2));
            let year = date.getFullYear() + '';
            let month = date.getMonth() + 1 > 9 ? (date.getMonth() + 1) + '' : '0' + (date.getMonth() + 1);
            let day = date.getDate() > 9 ? date.getDate() + '' : '0' + date.getDate();
            return year + '-' + month + '-' + day;

        },
        exportCSVBtn(){
            this.$loading(this.$t('msg.loading'));
            let langcode = this.$athlon.i18n.locale;
            langcode = langcode.replace(/-/g, '_');
            this.startDate = this.startDate || '';
            this.endDate = this.endDate || '';
            let channelIdParam = [];
            if(this.channelId != ""){
                channelIdParam.push(this.channelId);
            }else{
                channelIdParam = this.channelIds;
            }
            let merIdParam = [];
            if(this.merId != ""){
                merIdParam.push(this.merId);
            }else{
                merIdParam = this.merIds;
            }
            let transTypeParam = [];
            if(this.transType != ""){
                transTypeParam.push(this.transType);
            }else{
                transTypeParam = this.transTypes;
            }
            let transStateParam = [];
            if(this.transState != ""){
                transStateParam.push(this.transState);
            }else{
                transStateParam = this.transStates;
            }
            let channelIds = "";
            let transTypes = "";
            let transStates = "";
            let merIds = "";
            for(let i = 0; i < channelIdParam.length; i++){
                if(i == channelIdParam.length-1){
                    channelIds += channelIdParam[i]
                }else{
                    channelIds += channelIdParam[i]+","
                }
            }
            for(let i = 0; i < transTypeParam.length; i++){
                if(i == transTypeParam.length-1){
                    transTypes += transTypeParam[i]
                }else{
                    transTypes += transTypeParam[i]+","
                }
            }
            for(let i = 0; i < transStateParam.length; i++){
                if(i == transStateParam.length-1){
                    transStates += transStateParam[i]
                }else{
                    transStates += transStateParam[i]+","
                }
            }
            for(let i = 0; i < merIdParam.length; i++){
                if(i == merIdParam.length-1){
                    merIds += merIdParam[i]
                }else{
                    merIds += merIdParam[i]+","
                }
            }
            const params = {
                "startDate" : this.startDate,
                "endDate": this.endDate,
                "channelIds": channelIds,
                "transTypes": transTypes,
                "transStates" : transStates,
                "merIds": merIds,
                "termId": this.termId,
                "merTransId":this.merTransId,
                "langCode": langcode
                };
            this.downloadByPost("transRec/exportTransRec", params);
        },
        downloadAttach(attach){
            this.$loading(this.$t('msg.loading'));
            const params = {
                "attach" : attach,
            };
            this.$athlon.request
                .post('transRec/downloadAttach',params, this.$athlon.config.api.requestParamheaders)
                .then((res) => {
                    if(res.data.code == 0){
                        this.$dialog.open({
                            title: this.$t('transRec.msg.viewAttach'),
                            maxWidth:'500',
                            component: {
                                ctor: FormComponent,
                                props: { data: res.data.data },
                            },
                            footBtn: [
                                {eventId: 12, color: 'default',icon:'mdi-close', text: this.$t('athlon.common.back')},
                            ],
                            onaction: (e, data) => {
                                if(e.eventId == 12){
                                        this.$dialog.close();
                                    }
                                }
                        })
                    }else{
                        this.$snackbar(this.$t('transRec.msg.viewAttachError'));
                    }
                    this.$loading(false);
                })
                .catch((err) => {
                    this.$loading(false);
                    this.$snackbar(this.$t('error.500')+`：${err.message}`);
                });
        }
    },

};
const FormComponent = {
	props: ['data'],
    template: `
    <div>
        <v-row>
            <v-col>
                <img id="attachImg" :src="attach" style="width:280px;"/>
            </v-col>
        </v-row>
        </v-row>
    </div>`,
	data() {
		return {
            attach: "data:image/png;base64, "+this.data,
        };
    },
    created(){
    },
	methods: {

	},
}
</script>
